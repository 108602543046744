export default [{
  path: '/',
  label: 'HOME',
  label_ja: 'ホーム',
  componentName: 'home'
},
{
  path: '/#news',
  label: 'NEWS',
  label_ja: '最新情報',
  componentName: 'home'
},
{
  path: '/team',
  label: 'TEAM',
  label_ja: 'チーム情報',
  componentName: 'team'
},
{
  path: '/shop',
  label: 'STORE',
  label_ja: '公式ストア',
  componentName: 'shop'
},
{
  path: '/about',
  label: 'ABOUT',
  label_ja: '私たちについて',
  componentName: 'about'
},]