<video-content>
<div class="video-content-div-background">
  <div class="video-content-message">
    <div class="video-content-message-title-container">
      <p class="video-content-message-title">{props.title}</p>
      <p class="video-content-message-sub-title">{props.subtitle}</p>
    </div>
  </div>
  <img class="image-content-background" src="/img/top.png">
</div>
</video-content>
